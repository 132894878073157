
import {
  defineComponent,
  ref,
  inject,
  reactive,
  onMounted,
  nextTick,
  computed,
} from "vue";
import fixedHeader from "@/components/common/Header.vue";
import Toast from "@/components/UI/Toast";
import api from "@/api/axios";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "SetPassword",
  emits: ["loginsuccess"],
  setup(props, ctx) {
    const router = useRouter();
    const route = useRoute();
    const isActive = ref(0);

    //code 登录
    const tel = sessionStorage.getItem("userName") || "";
    const userName = ref<string>(tel);
    const password = ref<string>("");
    const repeatPass = ref<string>("");
    const sendCodeFlag = ref<boolean>(true);
    const codeText = ref<string>("获取验证码");
    const count = ref(60);

    function checkTel(tel: string) {
      if (tel === "") {
        Toast({
          title: "请输入手机号码",
          type: "warning",
        });
        return false;
      }
      const retel = /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/;
      if (tel.length != 11 || !retel.test(tel)) {
        Toast({
          title: "请输入正确的手机号码",
          type: "warning",
        });
        // $('#tel').focus();
        return false;
      }
      return true;
    }

    //  登录成功处理
    const UPDATE_USERINFO = inject("UPDATE_USERINFO") as (data: object) => void;
    function updateToken(token: string) {
      localStorage.setItem("token", token);
    }

    function handleUserInfo(data: object) {
      UPDATE_USERINFO(data);
    }

    //验证码登录
    function handleCodeLogin() {
      const formData = new FormData();
      formData.append("userName", userName.value);
      formData.append("password", password.value);
      api
        .post("/M/Server/setPassWords", formData)
        .then((res) => {
          if (res.data.success) {
            updateToken(res.data.token);
            handleUserInfo(res.data.obj);
            Toast({
              title: "Login succeeded",
              type: "success",
            });
            ctx.emit("loginsuccess");
            const redirect = (route.query.redirect ? route.query.redirect : "/") as string;
            router.push(redirect)
          } else {
            Toast({
              title: res.data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          // Toast({
          //   title: "未知错误，请联系客服",
          //   type: "success",
          // });
        });
    }



    return {
      isActive,
      userName,
      password,
      repeatPass,
      codeText,
      handleCodeLogin,
    };
  },
});
